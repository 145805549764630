.pnf-container {
    color: yellow;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50dvh;
    text-shadow: 3px 2px 2px rgba(251, 12, 12,1), 0px -1px 3px rgba(12, 79, 251,.5),-3px 0px 2px rgba(52, 251, 12, 1);
    animation: chromaticMove 4500ms ease infinite,pulse ease 200ms  infinite;
}

.pnf-wrapper {
    text-align: center;
}

a {
    color: white;
}

@keyframes gradientMove { 
    0%{background-size: 100% 4px}
    50%{background-size: 100% 2px}
    100%{background-size: 100% 4px}
}

@keyframes pulse { 
    0%{ opacity:.95;transform: skewX(.5deg);}
    25%{ opacity:1;}
    50%{opacity:.95;}
    75%{ opacity:1; transform:skewX(.5deg);}
    100%{opacity:.95;}
}

@keyframes light-pulse { 
  0%{ opacity:.95;transform: skewX(.1deg);}
  25%{ opacity:1;}
  50%{opacity:.95;}
  75%{ opacity:1; transform:skewX(.1deg);}
  100%{opacity:.95;}
}

@keyframes chromaticMove { 
    0%{text-shadow: 3px 2px 2px rgba(251, 12, 12,1), 0px -1px 3px rgba(12, 79, 251,.5),0px 0px -2px rgba(52, 251, 12, 1);}
    50%{text-shadow: 3px 2px 2px rgba(251, 12, 12,1), 0px -1px 3px rgba(12, 79, 251,.5),-3px 2px 3px rgba(52, 251, 12, 1);}
    100%{text-shadow: 3px 2px 2px rgba(251, 12, 12,1), 0px -1px 3px rgba(12, 79, 251,.5),2px -1px 2px rgba(52, 251, 12, 1);}
}