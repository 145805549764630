@import './assets/colors.css';

.App {
  text-align: center;
  overflow: hidden;
}

.img-col {
  display: flex;
  padding-bottom: 20px;
}

.img-wrapper {
  display: block;
  text-align: left;
  width: fit-content;
  padding: 0 7.5% 0 7.5%;
}

.card-row {
  display: flex;
  padding-top: 1rem;
  width: fit-content;
}

.skill-card {
  display: flex;
  justify-content: start;
  height: fit-content;
  width: calc(100%/2);
  max-width: 200px;
  margin-right: 1.5rem;
}

.potrait-photo,
.card-photo {
  width: 100%;
  height: auto;
  animation: chromaticMove 4500ms ease infinite,light-pulse ease 200ms  infinite;
  border: 4px solid white;
  box-shadow: 10px 5px 5px rgba(39, 39, 39, .5);
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 1);
  overflow: visible;
  border-radius: .25rem;
}

.title-col,
.img-col {
  padding-top: 20px;
}

body{
  background:#000;
  color:yellow;
  text-align:left;
  font-family:"Trebuchet MS";
  text-transform:uppercase;
  padding-top:2em;

  background: linear-gradient(
    to bottom,
    blue,
    blue 50%,
    rgb(0, 50, 255) 50%,
    rgb(0, 50, 255)
  );
  background-size: 100% 4px;
  animation: gradientMove 200ms ease infinite;
}

.body-container {
  color: yellow;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.title-col h1{
    font-size: 4em !important;
    text-shadow: 3px 2px 2px rgba(251, 12, 12,1), 0px -1px 3px rgba(12, 79, 251,.5),-3px 0px 2px rgba(52, 251, 12, 1);
    animation: chromaticMove 4500ms ease infinite,pulse ease 200ms  infinite;
    font-weight: bold;
}

.title-col h2 {
  color: white !important;
  text-shadow: 3px 2px 2px rgba(251, 12, 12,1), 0px -1px 3px rgba(12, 79, 251,.5),-3px 0px 2px rgba(52, 251, 12, 1);
  animation: chromaticMove 4500ms ease infinite,pulse ease 200ms  infinite;
  font-weight: bold;
}

.title-col {
  text-align: left;
}

.sub-header {
  color: white;
  text-align: left;
}

.tech-social {
  display: flex;
  width: 100%;
}

.additional-info {
  padding-top: 2rem;
}

.info-wrapper {
  display: flex;
  justify-content: center;
}

.email-txt {
  font-weight: bold;
  font-size: 3.5rem;
  margin-left: 1.5rem;
  color: yellow;
  text-shadow: 3px 2px 2px rgba(251, 12, 12,1), 0px -1px 3px rgba(12, 79, 251,.5),-3px 0px 2px rgba(52, 251, 12, 1);
}

.email-now {
  color: white;
}

.tech-header {
  text-align: left;
  font-size: 1.65rem;
  color: white;
  text-shadow: 3px 2px 2px rgba(251, 12, 12,1), 0px -1px 3px rgba(12, 79, 251,.5),-3px 0px 2px rgba(52, 251, 12, 1);
}

.frameworks,
.languages,
.tools {
  display: flex;
}

.tech-list {
  padding-left: 0.5rem;
}

.skills p {
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 0.5rem;
  color: white;
}

li {
  display: inline-flex;
  padding-left: 0.5rem;
  color: white;
}

.resume-link {
  display: flex;
  justify-content: right;
  align-items: center;
  animation: chromaticMove 4500ms ease infinite,light-pulse ease 200ms  infinite;
  padding-bottom: 0.5rem;
}

.resume-img {
  width: 10%;
  max-width: 300px;
  max-height: 40dvh;
}

@media screen and (max-width: 1250px) {
  .info-wrapper,
  .tech-social {
    display: block;
  }

  .info-wrapper {
    text-align: center;
  }

  .email-txt {
    margin-left: 0;
  }

  .resume-link {
    justify-content: center;
    padding-top: 1rem;
  }

  .resume-img {
    width: 20%;
  }
}

@media screen and (max-width: 992px) {
  .tech-social {
    display: flex;
    margin-top: 1.5rem;
  }

  .additional-info-row {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 880px) {
  .body-container {
    padding: 0.5rem 0 2.5rem 0;
  }

  .email-txt {
    font-size: 2.25rem;
  }
}

@media screen and (max-width: 768px) {
  .img-wrapper {
    padding: 0;
  }

  .skill-card:nth-child(2)
  {
    margin-right: 0;
  }

  .title-col h1{
    font-size: 1.5rem !important;
  }
  
  .frameworks,
  .languages,
  .tools {
    display: block;
  }

  .tech-list {
    padding-left: 0;
  }
  
  .info-wrapper {
    text-align: left;
  }
  
  .email-txt {
    font-size: 1.15rem;
  }

  .resume-img {
    width: 40%;
  }
}

@keyframes gradientMove { 
    0%{background-size: 100% 4px}
    50%{background-size: 100% 2px}
    100%{background-size: 100% 4px}
}

@keyframes pulse { 
    0%{ opacity:.95;transform: skewX(.5deg);}
    25%{ opacity:1;}
    50%{opacity:.95;}
    75%{ opacity:1; transform:skewX(.5deg);}
    100%{opacity:.95;}
}

@keyframes light-pulse { 
  0%{ opacity:.95;transform: skewX(.1deg);}
  25%{ opacity:1;}
  50%{opacity:.95;}
  75%{ opacity:1; transform:skewX(.1deg);}
  100%{opacity:.95;}
}

@keyframes chromaticMove { 
    0%{text-shadow: 3px 2px 2px rgba(251, 12, 12,1), 0px -1px 3px rgba(12, 79, 251,.5),0px 0px -2px rgba(52, 251, 12, 1);}
    50%{text-shadow: 3px 2px 2px rgba(251, 12, 12,1), 0px -1px 3px rgba(12, 79, 251,.5),-3px 2px 3px rgba(52, 251, 12, 1);}
    100%{text-shadow: 3px 2px 2px rgba(251, 12, 12,1), 0px -1px 3px rgba(12, 79, 251,.5),2px -1px 2px rgba(52, 251, 12, 1);}
}